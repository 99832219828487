.lucide {
  width: 18px;
  height: 18px;
}

.lucide-sm {
  width: 14px;
  height: 14px;
}

.lucide-lg {
  width: 36px;
  height: 36px;
}
