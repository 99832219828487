.stat {
  background: $stat-bg;
  border-radius: 50%;
  padding: 0.75rem;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;

  svg {
    width: 24px;
    height: 24px;
    color: $stat-icon-color;
  }

  i {
    font-size: 24px;
    color: inherit;
  }
}

@include color-mode(dark) {
  .stat {
    background: $stat-bg-dark;

    svg {
      color: $stat-icon-color-dark;
    }
  }
}

.stat-sm {
  width: 40px;
  height: 40px;
  padding: 0.625rem;

  svg {
    width: 20px;
    height: 20px;
  }

  i {
    font-size: 20px;
  }
}