.react-flow__attribution {
  display: none !important;
}

.react-flow-wrapper {
  transform: scale(0.80);
  transform-origin: top left; /* Adjust the origin to control scaling position */
}

.sidebar-dropdown .sidebar-item.active > .sidebar-link:not([data-bs-toggle]), .sidebar-dropdown .sidebar-item > .sidebar-link.active {
  color: #c84337 !important;
}

.custom-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.record-line {
  cursor: pointer;
}

.font-bolding {
  color: white !important;
  font-weight: 700 !important;
  font-size: 15px;
}

/* styles.css or any other CSS file */
.custom-modal-width .modal-dialog {
  max-width: 100%;
  width: 100%;
}

.report-th-not-bold{
   font-weight: 500; /* standard text weight */
}

.report-th-bolder{
  font-weight: 600; /* standard text weight */
}

.report-downloading-message-display{
  display:flex;
  justify-content:center;
  align-content:center;
}

.report-downloading-message-display .download-message{
  text-align:center;
  font-weight:700;
  font-size:15px;
  padding: 0px 20px 12px 0px;

}