.hamburger,
.hamburger:before,
.hamburger:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: $hamburger-width-middle;
  background: $hamburger-bg;
  display: block;
  content: '';
  transition: $transition-appearance-fast;
}

.hamburger {
  position: relative;
}

.hamburger:before {
  top: -8px;
  width: $hamburger-width-top;
  position: absolute;
}

.hamburger:after {
  bottom: -8px;
  width: $hamburger-width-bottom;
  position: absolute;
}

.sidebar-toggle:hover {
  .hamburger,
  .hamburger:before,
  .hamburger:after {
    background: $primary;
  }
}

.hamburger-right {
  &,
  &:before,
  &:after {
    right: 0;
  }
}
