.illustration {
  background: $illustration-bg;
  color: $illustration-color;
}

.illustration-text {
  color: $illustration-color;
}

@include color-mode(dark) {
  .illustration {
    background: $illustration-bg-dark;
    color: $illustration-color-dark;
  }
  
  .illustration-text {
    color: $illustration-color-dark;
  }
}

.illustration-img {
  max-width: 150px;
  width: 100%;

  @include media-breakpoint-up(xxl) {
    max-width: 175px;
  }
}