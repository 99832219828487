.min-vw-50 {
  min-width: 50vw;
}

.min-vh-50 {
  min-height: 50vh;
}

.vw-50 {
  width: 50vw;
}

.vh-50 {
  height: 50vh;
}

.w-25px {
  width: 25px;
}

.h-25px {
  height: 25px;
}

.w-50px {
  width: 50px;
}

.h-50px {
  height: 50px;
}

.w-75px {
  width: 75px;
}

.h-75px {
  height: 75px;
}

.w-100px {
  width: 100px;
}

.h-100px {
  height: 100px;
}

.mw-25px {
  max-width: 25px;
}

.mh-25px {
  max-height: 25px;
}

.mw-50px {
  max-width: 50px;
}

.mh-50px {
  max-height: 50px;
}

.mw-75px {
  max-width: 75px;
}

.mh-75px {
  max-height: 75px;
}

.mw-100px {
  max-width: 100px;
}

.mh-100px {
  max-height: 100px;
}