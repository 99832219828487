/* Scrollbar styles */
.scrollbar-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.ps {
  touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  position: absolute;
  left: auto !important;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.5;
}

.ps__thumb-x {
  background-color: #fff;
  border-radius: 7px;
  transition: background-color .2s linear, height .2s ease-in-out;
  height: 7px;
  bottom: 2px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #fff;
  border-radius: 7px;
  transition: background-color .2s linear, width .2s ease-in-out;
  width: 7px;
  right: 2px;
  position: absolute;
}


