.table-email {
  .col-checkbox {
    width: 15px;
  }
  .col-favorite {
    width: 15px;
  }
  .col-name {
    width: 100px;
  }
  .col-date {
    width: 100px;
  }
  .col-subject {
    max-width: 0;
  }
}