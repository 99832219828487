$settings-width: 320px;

.settings {
  display: none;
  direction: ltr;

  @include media-breakpoint-up(xl) {
    display: block;
  }
}

.settings-toggle {
  background: var(--#{$prefix}secondary-bg);
  color: var(--#{$prefix}secondary-color);
  position: fixed;
  top: 200px;
  z-index: 10;
  right: 0;
  width: 54px;
  height: 166px;
  padding: $spacer*0.5;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.1);
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.settings-toggle-option {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  writing-mode: vertical-rl;
  padding: $spacer*0.5;
  border-radius: $border-radius;
  height: 36px;
  color: var(--#{$prefix}secondary-color);
  background: darken($gray-100, 2);

  &:hover {
    background: darken($gray-100, 5);
  }
  
  &:first-child {
    color: $primary;
    margin-bottom: $spacer*0.5;
  }

  svg {
    stroke-width: 2;
  }
}

@include color-mode(dark) {
  .settings-toggle {
    background: $gray-900-dark;
  }

  .settings-toggle-option {
    background: $gray-800-dark;
    color: $white;

    &:hover {
      background: $gray-700-dark;
    }
  }
}

.settings-toggle-option-text {
  height: 106px;

  svg {
    transform: rotate(-90deg);
  }
}

.settings-panel {
  background: var(--#{$prefix}secondary-bg);
  border-left: $card-border-width solid $card-border-color;
  display: block;
  height: 100%;
  position: fixed;
  width: $settings-width;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: -$settings-width;
  transition: right .2s ease-in-out;

  .settings.open &:before {
    content: "";
    background: rgba($black, 0.325);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    pointer-events: none;
  }

  .settings.open & {
    right: 0;
  }
}

.settings-content {
  height: 100%;
  overflow: auto;
  position: relative;
  background: var(--#{$prefix}secondary-bg);
  display: flex;
  flex-direction: column;
}

.settings-title {
  padding: $spacer*1.5;
  font-size: .875rem;
  background: var(--#{$prefix}secondary-bg);
  color: var(--#{$prefix}secondary-color);
  border-bottom: 1px solid var(--#{$prefix}border-color);

  h4,
  .close {
    color: var(--#{$prefix}emphasis-color);
  }
}

.settings-body {
  padding: $spacer $spacer*1.5;
  flex: 1;
}

.settings-footer {
  padding: $spacer $spacer*1.5;
}

.settings-scheme:after {
  content: "✓";
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  font-weight: 900;
  font-size: 10px;
  line-height: 17px;
  z-index: 1;
  border-radius: 50%;
  color: var(--#{$prefix}secondary-bg);
  background: $primary;
  opacity: 0;
}

.settings-scheme-label {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.settings-scheme-label + .settings-scheme {
  border: 1px solid var(--#{$prefix}border-color);
}

.settings-scheme-label:checked + .settings-scheme {
  border: 1px solid $primary;
  color: $primary;

  &:after {
    opacity: 1;
  }
}

.settings-scheme {
  background: var(--#{$prefix}secondary-bg);
  height: 80px;
  border-radius: $border-radius-lg;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-base;
  position: relative;
}

.settings-scheme-theme {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px $white;
  position: relative;
}

.settings-scheme-theme-default {
  background-image: linear-gradient(-45deg, #222E3C 50%, darken($gray-100, 2%) 50%);
}

.settings-scheme-theme-colored {
  background-image: linear-gradient(-45deg, $primary 50%, darken($gray-100, 2%) 50%);
}

.settings-scheme-theme-dark {
  background: #222E3C;
}

.settings-scheme-theme-light {
  background: darken($gray-100, 2%);
}

.settings-button-label {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.settings-button-label + .settings-button {
  background: var(--#{$prefix}quaternary-bg);
  color: var(--#{$prefix}emphasis-color);
  border-radius: 5px;
  padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
  font-size: $input-btn-font-size;
  border-radius: $btn-border-radius-lg;
  cursor: pointer;
}

.settings-button-label:checked + .settings-button {
  background: $primary;
  color: $white;

  &:after {
    opacity: 1;
  }
}